import React, { Component} from "react";
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Person from '@material-ui/icons/Person';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import RecentActors from '@material-ui/icons/RecentActors';
import Email from '@material-ui/icons/Email';
import SaveIcon  from '@material-ui/icons/Save';
import ThumbDownAltIcon  from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAltIcon  from '@material-ui/icons/ThumbUpAlt';
import LoopIcon from '@material-ui/icons/Loop';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import "./form.style.css";


  /*
  const businesses = [];
  businesses.push({id:"Mensing's Caminada",key:"mensings", nr:"200"});
  businesses.push({id:"Il Gelato",key:"il-gelato", nr:"202"});
  businesses.push({id:"Chit Chat Cafe",key:"chit-chat", nr:"203"});
  businesses.push({id:"Pizzarroni",key:"pizzarroni", nr:"204"});
  businesses.push({id:"Pizza Mare",key:"pizza-mare", nr:"205"});
  businesses.push({id:"Bella Italia Zuikertuin",key:"bella-italia", nr:"206"});
  businesses.push({id:"Dagaz Gelato",key:"dagaz-gelato", nr:"207"});
  businesses.push({id:"Bob Esprit Gym",key:"bob-esprit", nr:"208"});
  businesses.push({id:"Aqua Curacao",key:"aqua-curacao", nr:"209"});
  businesses.push({id:"Optica Antillana",key:"optica-antillana", nr:"212"});
  businesses.push({id:"Number Ten",key:"number-ten", nr:"213"});
  businesses.push({id:"La Boheme",key:"la-boheme", nr:"214"});
  businesses.push({id:"Mistral",key:"mistral", nr:"215"});
  businesses.push({id:"Meow",key:"meow", nr:"216"});
  */
  
function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error("timeout"))
    }, ms)
    promise.then(resolve, reject)
  })
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: "",
      businesses: [],
      myName: "",
      myId: "",
      myPhoneOne: "",
      myPhoneTwo: "",
      myEmail: "",
      checked: false,
      active: 'form',
      sending: false,
    };
    
    timeout = timeout.bind(this);
    //this.toggleMenu = this.toggleMenu.bind(this);
  }
  
  async componentDidMount(){
    this.setState({
      myName: window.localStorage.getItem('myName'),
      myId: window.localStorage.getItem('myId'),
      myPhoneOne: window.localStorage.getItem('myPhoneOne'),
      myPhoneTwo: window.localStorage.getItem('myPhoneTwo'),
      myEmail: window.localStorage.getItem('myEmail')
    });
    
    await this._getBus();
  }
  
  async _getBus(){
    fetch("https://api.curacao311.com/checkincw/send.php")
      .then(res => res.json())
      .then(
        (result) => {
          const businesses = [];
          result.map((item, key) => {
            businesses.push({id:item.businessName,key:item.abbrev, nr:item.busid})
          });
          this.setState({
            businesses: businesses
          });
          
          if(window.location.pathname.substr(1)!==""){
            const pathName = window.location.pathname.substr(1);
            result.map((item,key)=>{
              if(item.abbrev===pathName){
                this.setState({business: pathName});
              }
            })
          }
          
        },
        (error) => {
          console.log(error)
        }
      )
  }
  
  _saveValue(field,value){
    //window.localStorage.setItem(field,value);
    this.setState({
      [field]: value
    });
  }
  
  _saveForm(){
    if(this.state.business!=="" && this.state.myName.length>3 && this.state.myId.length>6 && this.state.myPhoneOne.length>6) {
      window.localStorage.setItem('myName',this.state.myName);
      window.localStorage.setItem('myId',this.state.myId);
      window.localStorage.setItem('myPhoneOne',this.state.myPhoneOne);
      window.localStorage.setItem('myPhoneTwo',this.state.myPhoneTwo);
      window.localStorage.setItem('myEmail',this.state.myEmail);
      this._goTo('confirmation');
    }
    else {
      window.alert('Please enter at least the required fields completely');
    }
  }
  
  timeout(ms, promise) {
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        reject(new Error("timeout"))
      }, ms)
      promise.then(resolve, reject)
    })
  }
  
  async _sendForm(){
    // we have data, let's send it
    this.setState({ sending: true });
    
    //get the business nr
    let busNr, busName = "";
    this.state.businesses.map((item,key)=>{
      if(item.key===this.state.business){
        busNr = item.nr;
        busName = item.id;
      }
    })
    const business = busName+"|"+busNr;
    //console.log(business);
    
    var data = new FormData();
    data.append('theName',this.state.myName);
    data.append('sedula',this.state.myId);
    data.append('phoneOne',this.state.myPhoneOne);
    data.append('phoneTwo',this.state.myPhoneTwo);
    data.append('email',this.state.myEmail);
    data.append('theData',business);
    
    this.timeout(3000, fetch('https://api.curacao311.com/ping', { method: 'HEAD', mode: 'no-cors' })).then((response) => {
      const config = {
          method: 'POST',
          body: data
      };
      fetch('https://api.curacao311.com/checkincw/receive.php', config)
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.status==="success" && responseJson.business) {
          this.setState({ 
            sending: false,
            checked: false
          },()=>this._goTo('success'))
          
        }
        else {
          window.alert("Something went wrong entering your information.\n\n" + responseJson.message);
          //console.log(responseJson);
          this.setState({ 
            sending: false,
          });
          //this.props.goTo('start');
        }
      })
      .catch((error) => {
        //console.error(error);
        this.setState({ 
          sending: false
        });
      });
    }).catch((error) => {
      // might be a timeout error
      window.alert("It looks like you're not online so we can't register your checkin.");
      console.log(error);
      this.setState({ 
        sending: false
      });
      //this.props.goTo('start');
    })
    //this.props.goTo('start');
    return;
  }
  
  _goTo(where){
    this.setState({
      active: where,
    });
  }
  
  
  render(){
    
  
    return(
      <div className="page">
        <div className="header">
          <img src="/images/logo.png" alt="Checkin CW" />
        </div>
        {this.state.active==='form' &&
          <div className="fullForm">
            
            <FormControl className="formSelect">
              <Select
                id="business"
                displayEmpty
                value={this.state.business}
                onChange={(el)=>this._saveValue("business",el.target.value)}
              >
                <MenuItem value="">
                  <em>Choose a business*</em>
                </MenuItem>
                {
                  this.state.businesses && this.state.businesses.length>0 && this.state.businesses.map((item,key)=>{
                    return (
                      <MenuItem key={key} value={item.key} name={item.id}>{item.id}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          
            <FormControl className="formInput">
              <InputLabel htmlFor="myName">Your name*</InputLabel>
              <Input
                id="myName"
                placeholder="Your name here"
                onChange={(el)=>this._saveValue("myName",el.target.value)}
                value={this.state.myName}
                startAdornment={
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                }
              />
            </FormControl>
            
            <FormControl className="formInput">
              <InputLabel htmlFor="myId">Your sedula/passport number*</InputLabel>
              <Input
                id="myId"
                placeholder="Sedula/passport number"
                onChange={(el)=>this._saveValue("myId",el.target.value)}
                value={this.state.myId}
                startAdornment={
                  <InputAdornment position="start">
                    <RecentActors />
                  </InputAdornment>
                }
              />
            </FormControl>
            
            <FormControl className="formInput">
              <InputLabel htmlFor="myPhoneOne">Phone number 1*</InputLabel>
              <Input
                id="myPhoneOne"
                placeholder="Phone number here"
                onChange={(el)=>this._saveValue("myPhoneOne",el.target.value)}
                value={this.state.myPhoneOne}
                startAdornment={
                  <InputAdornment position="start">
                    <PhoneAndroid />
                  </InputAdornment>
                }
              />
            </FormControl>
            
            <FormControl className="formInput">
              <InputLabel htmlFor="myPhoneTwo">Phone number 2</InputLabel>
              <Input
                id="myPhoneTwo"
                placeholder="Phone number here"
                onChange={(el)=>this._saveValue("myPhoneTwo",el.target.value)}
                value={this.state.myPhoneTwo}
                startAdornment={
                  <InputAdornment position="start">
                    <PhoneAndroid />
                  </InputAdornment>
                }
              />
            </FormControl>
            
            <FormControl className="formInput">
              <InputLabel htmlFor="myEmail">Your email address</InputLabel>
              <Input
                id="myEmail"
                placeholder="Email address here"
                onChange={(el)=>this._saveValue("myEmail",el.target.value)}
                value={this.state.myEmail}
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
              />
            </FormControl>
            
            <FormControl className="formCheckbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checked}
                    onChange={()=>this.setState({checked:!this.state.checked})}
                    color="default"
                  />
                }
                label="I certify that the above information is correct and agree to share it with the proprietor of the abovementioned business."
              />
            </FormControl>
            
            <Button
              variant="contained"
              className="formSubmit"
              startIcon={<SaveIcon />}
              disabled={!this.state.checked}
              onClick={()=>this._saveForm()}
            >
              CHECK IN
            </Button>
          </div>
        }
        {this.state.active==='confirmation' &&
          <div className="fullForm">
            <div className="confirmation">
              You are at
              <div className="conf-business">
              {
                this.state.businesses && this.state.businesses.map((item,key)=>{
                  if(item.key===this.state.business){
                    return (
                      item.id
                    )
                  }
                })
              }
              </div>
              You will send:
              <div className="conf-data">
                Name: {this.state.myName}<br />
                ID: {this.state.myId}<br />
                Phone 1: {this.state.myPhoneOne}<br />
                {this.state.myPhoneTwo!=="" &&
                  <span>
                    Phone 2: {this.state.myPhoneTwo}<br />
                  </span>
                }
                {this.state.myEmail!=="" &&
                  <span>
                    Email: {this.state.myEmail}<br />
                  </span>
                }
              </div>
              Do you want to check in here?
              <div className="conf-buttons">
                <Button
                  variant="contained"
                  className="sayNo"
                  startIcon={<ThumbDownAltIcon />}
                  onClick={()=>this._goTo('form')}
                >
                  NO
                </Button>
                
                {!this.state.sending &&
                  <Button
                    variant="contained"
                    className="sayYes"
                    startIcon={<ThumbUpAltIcon />}
                    onClick={()=>this._sendForm()}
                  >
                    YES
                  </Button>
                }
                
                {this.state.sending &&
                  <Button
                    variant="contained"
                    className="sayYes animated"
                  >
                    <LoopIcon />
                  </Button>
                }
              </div>
            </div>
          </div>
        }
        
        {this.state.active==='success' &&
          <div className="fullForm suc6">
            <div className="success">
              You have checked in at
              <div className="conf-business">
              {
                this.state.businesses && this.state.businesses.map((item,key)=>{
                  if(item.key===this.state.business){
                    return (
                      item.id
                    )
                  }
                })
              }
              </div>
              Please show this confirmation to the attendant at the door.
            </div>
            
            <div className="haveSent">
              You have sent:
              <div className="conf-data">
                Name: {this.state.myName}<br />
                ID: {this.state.myId}<br />
                Phone 1: {this.state.myPhoneOne}<br />
                {this.state.myPhoneTwo!=="" &&
                  <span>
                    Phone 2: {this.state.myPhoneTwo}<br />
                  </span>
                }
                {this.state.myEmail!=="" &&
                  <span>
                    Email: {this.state.myEmail}<br />
                  </span>
                }
              </div>
            </div>
            
            <div className="conf-buttons">
              <Button
                variant="contained"
                className="back"
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={()=>this._goTo('form')}
              >
                BACK
              </Button>
            </div>
              
          </div>
        }
        
        <div className="bottom">
          <div className="download">
            <a href="https://play.google.com/store/apps/details?id=com.checkincw" target="_blank" rel="noopener noreferrer">
              <img src="/images/download-android.png" alt="Download for Android" />
            </a>
            <a href="https://apps.apple.com/us/app/checkin-cura%C3%A7ao/id1530256975" target="_blank" rel="noopener noreferrer">
              <img src="/images/download-apple.png" alt="Download for iOS" />
            </a>
          </div>
          
          <div className="footer">
            <a href="https://www.curacao311.com" target="_blank" rel="noopener noreferrer">
              <img src="/images/powered-by.png" alt="Powered by Curacao 311" />
            </a>
          </div>
        </div>
      </div>
    );
  }
  
}
export default Form;