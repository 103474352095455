import React from 'react';
import Form from './components/form';
import './App.css';

function App() {
  return (
    <Form />
  );
}

export default App;
